<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex
        xs12
        sm4
        md4
        lg4
        v-if="!selected && ownersBusinesses.length"
        class="py-1"
      >
        <v-select
          dense
          solo
          class="caption"
          :label="$t('search business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search payroll')"
        ></v-text-field>
      </v-flex>

      <v-flex
        xs12
        sm4
        md4
        lg4
        class="text-right py-1 px-1"
        v-if="!selectedShop"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :color="$store.state.secondaryColor"
              outlined
              small
            >
              {{ $t("monthly payroll") }}
            </v-btn>
          </template>
          <span>{{ $t("please select a business") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1" v-else>
        <v-btn color="success" outlined small @click="viewPayroll">
          {{ $t("monthly payroll") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="payroll"
          :search="search"
          :items-per-page="20"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar size="30">
              <v-img :src="item.avatar" style="cursor: pointer">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      width="5"
                      indeterminate
                      :color="$store.state.primaryColor"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.businessId`]="{ item }">
            {{ getBusinessName(item.businessId) }}
          </template>

          <template v-slot:[`item.names`]="{ item }">
            {{
              item.names.first + " " + item.names.middle + " " + item.names.last
            }}
          </template>
          <template v-slot:[`item.basicPay`]="{ item }">
            {{ currencyFormat(item.basicPay) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              x-small
              class="mx-1"
              left
              outlined
              :color="$store.state.primaryColor"
              @click="openDialog(item.names, item.id, item.employeeId)"
              >{{ $t("earnings and deductions") }}</v-btn
            >
            <v-btn
              x-small
              class="mx-1 white--text"
              left
              outlined
              color="success"
              @click="viewIndividualsPayroll(item.employeeId)"
              >{{ $t("pay slip") }}</v-btn
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("earnings and deductions") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items
            ><v-btn
              :loading="saveLoading"
              dark
              class="gradients white--text mx-1 py-1"
              style="border-radius: 5px"
              text
              @click="saveData()"
            >
              <v-icon left>save</v-icon>
              {{ $t("save") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-form ref="dateYear">
          <v-layout row wrap class="mt-5 mx-1">
            <v-flex xs12 sm4 md4 lg4>
              <span class="text-h4 font-weight-black black--text">{{
                employeeName.first +
                " " +
                employeeName.middle +
                " " +
                employeeName.last
              }}</span>
            </v-flex>
            <v-spacer></v-spacer>

            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <v-autocomplete
                dense
                solo
                class="caption"
                :label="$t('select month')"
                item-value="id"
                :rules="rules.emptyField"
                :items="months"
                v-model="selectedMonth"
                @change="onSelectMonth(selectedMonth)"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <v-autocomplete
                dense
                solo
                class="caption"
                :label="$t('select year')"
                item-value="id"
                :rules="rules.emptyField"
                :items="years"
                v-model="selectedYear"
                @change="onSelectYear(selectedYear)"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
        </v-form>
        <v-layout row wrap class="mt-5 mx-1">
          <v-flex xs12 sm12 md6 lg6 class="px-2">
            <v-alert>
              <v-form ref="earningsForm">
                <v-layout row wrap>
                  <v-flex xs12 sm5 md5 lg5>
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("earnings") }} :<span
                        v-if="customEarnings == false"
                      >
                        <v-btn
                          height="5"
                          small
                          text
                          class="blue--text text-lowercase text-decoration-underline"
                          @click="openCustomEarningsBox()"
                        >
                          {{ $t("custom") }}
                        </v-btn> </span
                      ><span v-else>
                        <v-btn
                          height="5"
                          small
                          text
                          class="blue--text text-lowercase text-decoration-underline"
                          @click="openDefaultEarningsBox()"
                        >
                          {{ $t("default") }}
                        </v-btn>
                      </span>
                    </span>

                    <v-textarea
                      v-if="customEarnings"
                      outlined
                      class="px-1"
                      dense
                      auto-grow
                      rows="1"
                      v-model="earningsName"
                      :rules="rules.emptyField"
                    ></v-textarea>

                    <v-autocomplete
                      v-else
                      v-model="earningsName"
                      :items="earnings"
                      item-text="earningsName"
                      item-value="earningsName"
                      dense
                      outlined
                      :rules="rules.emptyField"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm5 md5 lg5>
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("amount") }} :
                    </span>
                    <v-textarea
                      outlined
                      class="px-1"
                      dense
                      auto-grow
                      rows="1"
                      @keypress="digits"
                      v-model="earningsAmount"
                      :rules="rules.emptyField"
                    ></v-textarea>
                  </v-flex>

                  <v-flex xs12 sm2 md2 lg2 class="mt-6">
                    <v-btn color="success" @click="addEarningsList()"
                      >{{ $t("add") }} <v-icon>mdi-plus</v-icon></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-form>
              <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                  <v-divider></v-divider>

                  <v-list subheader dense>
                    <div v-if="this.$store.state.earnings.length != 0">
                      <v-subheader>{{ $t("earnings") }}</v-subheader>
                      <v-list-item
                        v-for="earning in this.$store.state.earnings"
                        :key="earning.earningsName"
                      >
                        <v-list-item-content>
                          <v-layout row wrap class="px-3">
                            <v-flex xs12 sm6 md6 lg6>
                              <v-list-item-title
                                class="caption"
                                v-text="earning.earningsName"
                              ></v-list-item-title>
                            </v-flex>
                            <v-flex xs12 sm6 md6 lg6>
                              <v-list-item-title
                                class="caption"
                                v-text="currencyFormat(earning.earningsAmount)"
                              ></v-list-item-title>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>

                        <v-list-item-icon>
                          <v-btn
                            class="mx-1"
                            color="red"
                            dark
                            x-small
                            @click="deleteEarning(earning.earningsName)"
                          >
                            {{ $t("delete") }}
                          </v-btn>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-1"
                                :color="$store.state.secondaryColor"
                                dark
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  saveEarningToDefault(earning.earningsName)
                                "
                              >
                                {{ $t("save") }}
                              </v-btn>
                            </template>
                            <span>{{ $t("save to default list") }}</span>
                          </v-tooltip>
                        </v-list-item-icon>
                      </v-list-item>
                    </div>

                    <div v-else>
                      <span class="caption">{{ $t("no earnings") }}</span>
                    </div>
                  </v-list>
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-alert>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 class="px-2">
            <v-alert>
              <v-form ref="deductionsForm">
                <v-layout row wrap>
                  <v-flex xs12 sm5 md5 lg5>
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("deductions") }} :<span
                        v-if="customDeductions == false"
                      >
                        <v-btn
                          height="5"
                          small
                          text
                          class="blue--text text-lowercase text-decoration-underline"
                          @click="openCustomDeductionsBox()"
                        >
                          {{ $t("custom") }}
                        </v-btn> </span
                      ><span v-else>
                        <v-btn
                          height="5"
                          small
                          text
                          class="blue--text text-lowercase text-decoration-underline"
                          @click="openDefaultDeductionsBox()"
                        >
                          {{ $t("default") }}
                        </v-btn>
                      </span>
                    </span>

                    <v-textarea
                      v-if="customDeductions"
                      outlined
                      class="px-1"
                      dense
                      auto-grow
                      rows="1"
                      v-model="deductionName"
                      :rules="rules.emptyField"
                    ></v-textarea>

                    <v-autocomplete
                      v-else
                      v-model="deductionName"
                      :items="deductions"
                      item-text="deductionName"
                      item-value="deductionName"
                      dense
                      outlined
                      :rules="rules.emptyField"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm5 md5 lg5>
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("amount") }} :
                    </span>
                    <v-textarea
                      outlined
                      class="px-1"
                      dense
                      auto-grow
                      @keypress="digits"
                      rows="1"
                      v-model="deductionAmount"
                      :rules="rules.emptyField"
                    ></v-textarea>
                  </v-flex>

                  <v-flex xs12 sm2 md2 lg2 class="mt-6">
                    <v-btn color="success" @click="addDeductionsList()"
                      >{{ $t("add") }} <v-icon>mdi-plus</v-icon></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-form>
              <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                  <v-divider></v-divider>
                  <v-list subheader dense>
                    <div v-if="this.$store.state.deductions.length != 0">
                      <v-subheader>{{ $t("deductions") }}</v-subheader>
                      <v-list-item
                        v-for="deduction in this.$store.state.deductions"
                        :key="deduction.deductionName"
                      >
                        <v-list-item-content>
                          <v-layout row wrap class="px-3">
                            <v-flex xs12 sm6 md6 lg6>
                              <v-list-item-title
                                class="caption"
                                v-text="deduction.deductionName"
                              ></v-list-item-title>
                            </v-flex>
                            <v-flex xs12 sm6 md6 lg6>
                              <v-list-item-title
                                class="caption"
                                v-text="
                                  currencyFormat(deduction.deductionAmount)
                                "
                              ></v-list-item-title>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>

                        <v-list-item-icon>
                          <v-btn
                            class="mx-1"
                            color="red"
                            dark
                            x-small
                            @click="deleteDeduction(deduction.deductionName)"
                          >
                            {{ $t("delete") }}
                          </v-btn>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-1"
                                :color="$store.state.secondaryColor"
                                dark
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  saveDeductonToDefault(deduction.deductionName)
                                "
                              >
                                {{ $t("save") }}
                              </v-btn>
                            </template>
                            <span>{{ $t("save to default list") }}</span>
                          </v-tooltip>
                        </v-list-item-icon>
                      </v-list-item>
                    </div>

                    <div v-else>
                      <span class="caption">{{ $t("no deductions") }}</span>
                    </div>
                  </v-list>
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-alert>
          </v-flex>
        </v-layout>
        <!-- <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 class="px-2">
            <v-data-table
              :headers="headersTwo"
              :loading="loading"
              :items="earningsDeductions"
              :search="search"
              :items-per-page="20"
            >
            </v-data-table>
          </v-flex>
        </v-layout> -->

        <!-- <v-layout row wrap class="mt-5 mx-1">
          <v-flex xs12 sm12 md6 lg6 class="px-2">
            <span class="text-h6 font-weight-black black--text">
              Total earnings: <span>{{ totalEarnings }}</span></span
            >
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md6 lg6 class="px-2">
            <span class="text-h6 font-weight-black black--text">
              Total deductions: <span>{{ totalDeductions }}</span></span
            >
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mt-5 mx-1">
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md6 lg6 class="px-2">
            <span class="text-h4 font-weight-black black--text">
              NetPay: <span>1000000</span></span
            >
          </v-flex>
        </v-layout> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("earnings and deductions updated successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{
        $t("could not update earnings and deductions. Try again later")
      }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackSuccessDeductionAdded"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("deductions added to default successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccessDeductionAdded = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackErrorDeductionAdded"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("deduction could not update. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackErrorDeductionAdded = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackSuccessEarningAdded"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("earning added to default successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccessEarningAdded = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackErrorEarningAdded"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("earning could not update. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackErrorEarningAdded = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackEarningLoading"
      top
      center
      color="info lighten-5 info--text text--darken-3"
      :timeout="12000"
    >
      <span>{{ $t("saving...") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="info darken-3"
          @click="snackEarningLoading = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackDeductionLoading"
      top
      center
      color="info lighten-5 info--text text--darken-3"
      :timeout="12000"
    >
      <span>{{ $t("saving...") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="info darken-3"
          @click="snackDeductionLoading = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";
import { digits } from "@/plugins/functions";
export default {
  data: () => ({
    loading: true,
    saveLoading: false,
    dialog: false,
    snackSuccess: false,
    snackError: false,
    snackSuccessDeductionAdded: false,
    snackEarningLoading: false,
    snackDeductionLoading: false,
    snackErrorDeductionAdded: false,
    snackErrorEarningAdded: false,
    snackSuccessEarningAdded: false,
    customDeductions: false,
    selectedYear: "",
    selectedMonth: "",
    customEarnings: false,
    earningsName: "",
    deductionName: "",
    totalDeductions: "",
    totalEarnings: "",
    paye: "",
    earningsAmount: "",
    deductionAmount: "",
    search: "",
    docId: "",
    employeeId: "",
    tempEarnings: [],
    tempDeductions: [],
    earningsList: {
      earningsAmount: "",
      earningsName: "",
    },
    headersTwo: [
      {
        text: "Avatar",
        value: "avatar",
        class: "blue-grey darken-3 white--text font-weight-regular",
      },
    ],
    employeeName: {},
    businesses: [],
    payroll: [],
    singlePayroll: [],
    selectedShop: "",
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },
    months() {
      return this.$store.getters.getMonths;
    },
    years() {
      return this.$store.getters.getYears;
    },
    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
    headers() {
      return [
        {
          text: this.$t("avatar"),
          value: "avatar",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("employee ID"),
          value: "employeeId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("name"),
          value: "names",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("employee at"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("basic pay"),
          value: "basicPay",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  created() {
    this.getPayrolls();
    this.getEarnings();
    this.getDeductions();
  },

  methods: {
    digits,
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },
    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },
    getShiftName(id) {
      console.log(this.$store.state.shifts);
      let shift = this.$store.state.shifts.find((item) => item.id == id);

      let shiftName = shift.shiftName;
      return shiftName;
    },

    openCustomEarningsBox() {
      this.customEarnings = true;
    },
    openDefaultEarningsBox() {
      this.customEarnings = false;
    },
    openCustomDeductionsBox() {
      this.customDeductions = true;
    },
    openDefaultDeductionsBox() {
      this.customDeductions = false;
    },
    getPayrolls() {
      // this.$store.commit("SET_EARNINGS", "");
      // this.$store.commit("SET_DEDUCTIONS", "");

      this.payroll = [];
      if (this.selected) {
        db.collection("payroll")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.payroll.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("payroll")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.payroll.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    // earningsDeductionsList() {
    //   var new_array;

    //   new_array = this.payroll.filter(
    //     (element) => element.employeeId == this.employeeId
    //   );
    //   console.log("new_array");
    //   console.log(new_array);
    // },
    getEarnings() {
      this.earnings = [];
      if (this.selected) {
        db.collection("earnings")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.earnings.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("earnings")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.earnings.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    getDeductions() {
      this.deductions = [];
      if (this.selected) {
        db.collection("deductions")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.deductions.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("deductions")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.deductions.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    onSelectYear(year) {
      this.selectedYear = year;
    },
    //function called when month is changed
    onSelectMonth(month) {
      this.selectedMonth = month;
    },
    openDialog(names, id, employeeId) {
      console.log(names);
      this.employeeName = names;
      this.docId = id;
      this.employeeId = employeeId;
      this.dialog = true;
    },
    onSelectShop() {
      this.loading = true;
      this.payroll = [];
      db.collection("payroll")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.payroll.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },
    addEarningsList() {
      if (this.$refs.earningsForm.validate()) {
        if (this.$refs.dateYear.validate()) {
          //send data to store
          this.tempEarnings = [];
          if (this.$store.state.earnings.length > 0) {
            //read data in store
            this.tempEarnings = this.$store.state.earnings;

            var index = this.months.findIndex(
              (item) => item == this.selectedMonth
            );
            console.log("month");
            console.log(this.selectedMonth);
            console.log(index);

            console.log(Array.isArray(this.tempEarnings));
            //form data
            this.earningsList = {
              earningsAmount: this.earningsAmount,
              earningsName: this.earningsName,
              dateTime: new Date(this.selectedYear, index),
            };

            this.tempEarnings.push(this.earningsList);

            this.$store.commit("SET_EARNINGS", this.tempEarnings);
          } else {
            index = this.months.findIndex((item) => item == this.selectedMonth);
            //form data
            this.earningsList = {
              earningsAmount: this.earningsAmount,
              earningsName: this.earningsName,
              dateTime: new Date(this.selectedYear, index),
            };
            this.tempEarnings.push(this.earningsList);

            this.$store.commit("SET_EARNINGS", this.tempEarnings);
          }
        }
      }
    },
    addDeductionsList() {
      if (this.$refs.deductionsForm.validate()) {
        if (this.$refs.dateYear.validate()) {
          //send data to store

          this.tempDeductions = [];
          if (this.$store.state.deductions.length > 0) {
            //read data in store
            this.tempDeductions = this.$store.state.deductions;

            var index = this.months.findIndex(
              (item) => item == this.selectedMonth
            );
            //form data
            this.deductionList = {
              deductionAmount: this.deductionAmount,
              deductionName: this.deductionName,
              dateTime: new Date(this.selectedYear, index),
            };

            this.tempDeductions.push(this.deductionList);

            this.$store.commit("SET_DEDUCTIONS", this.tempDeductions);
          } else {
            index = this.months.findIndex((item) => item == this.selectedMonth);
            //form data
            this.deductionList = {
              deductionAmount: this.deductionAmount,
              deductionName: this.deductionName,
              dateTime: new Date(this.selectedYear, index),
            };
            this.tempDeductions.push(this.deductionList);

            this.$store.commit("SET_DEDUCTIONS", this.tempDeductions);
          }
        }
      }
    },
    //sae earning and deductions on firebase
    saveData() {
      if (this.$refs.dateYear.validate()) {
        this.saveLoading = true;
        this.singlePayroll = [];
        //get users payroll data
        db.collection("payroll")
          .where("employeeId", "==", this.employeeId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.singlePayroll.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            if (typeof this.singlePayroll[0].monthlyPay != "undefined") {
              if (this.$store.state.deductions.length != 0) {
                //combine arrays
                let combinedDeductions = [].concat(
                  this.singlePayroll[0].monthlyPay.deductions,
                  this.$store.state.deductions
                );

                this.singlePayroll[0].monthlyPay.deductions =
                  combinedDeductions;
              }
            } else {
              let monthlyPay = {
                deductions: this.$store.state.deductions,
                earnings: [],
              };

              let data = {
                avatar: this.singlePayroll[0].avatar,
                businessId: this.singlePayroll[0].businessId,
                employeeId: this.singlePayroll[0].employeeId,
                basicPay: this.singlePayroll[0].basicPay,
                monthlyPay: monthlyPay,
                names: this.singlePayroll[0].names,
              };

              this.singlePayroll[0] = data;
            }

            if (this.singlePayroll[0].monthlyPay.earnings == "undefined") {
              if (this.$store.state.earnings.length != 0) {
                console.log("earnings not empty");
                //combine arrays
                // let combinedEarnings = [].concat(
                //   this.singlePayroll[0].monthlyPay.earnings,
                //   this.$store.state.earnings
                // );

                this.singlePayroll[0].monthlyPay.earnings =
                  this.$store.state.earnings;
              }
            } else if (
              this.singlePayroll[0].monthlyPay.earnings !== "undefined"
            ) {
              //combine arrays
              let combinedEarnings = [].concat(
                this.singlePayroll[0].monthlyPay.earnings,
                this.$store.state.earnings
              );

              this.singlePayroll[0].monthlyPay.earnings = combinedEarnings;
            } else {
              this.singlePayroll[0].monthlyPay.earnings.push(
                this.$store.state.earnings
              );
            }

            let data = {
              avatar: this.singlePayroll[0].avatar,
              businessId: this.singlePayroll[0].businessId,
              employeeId: this.singlePayroll[0].employeeId,
              basicPay: this.singlePayroll[0].basicPay,
              monthlyPay: this.singlePayroll[0].monthlyPay,
              names: this.singlePayroll[0].names,
            };

            db.collection("payroll")
              .doc(this.docId)
              .update(data)
              .then(() => {
                this.saveLoading = false;
                this.snackSuccess = true;

                this.$store.commit("SET_DEDUCTIONS", "");
                this.$store.commit("SET_EARNINGS", "");
                // this.filterPayroll();
              })
              .catch(() => {
                this.saveLoading = false;
                this.snackError = true;
              });
          });
      }
    },

    deleteEarning(val) {
      let productIndex = this.tempEarnings
        .map((item) => item.earningsName)
        .indexOf(val);

      this.tempEarnings.splice(productIndex, 1);
      this.$store.commit("SET_EARNINGS", this.tempEarnings);
    },
    deleteDeduction(val) {
      let productIndex = this.tempDeductions
        .map((item) => item.earningsName)
        .indexOf(val);

      this.tempDeductions.splice(productIndex, 1);
      this.$store.commit("SET_DEDUCTIONS", this.tempDeductions);
    },
    //add earnings to default
    saveEarningToDefault(earningName) {
      this.snackEarningLoading = true;
      this.singlePayroll = [];
      //get users payroll data
      db.collection("payroll")
        .where("employeeId", "==", this.employeeId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.singlePayroll.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          let data = {
            earningsName: earningName,
            businessId: this.singlePayroll[0].businessId,
          };
          db.collection("earnings")
            .add(data)
            .then(() => {
              this.snackEarningLoading = false;
              this.snackSuccessEarningAdded = true;
            })
            .catch(() => {
              this.snackEarningLoading = false;
              this.snackErrorEarningAdded = true;
            });
        });
    },

    //add deduction to default
    saveDeductonToDefault(deductionName) {
      this.snackDeductionLoading = true;
      this.singlePayroll = [];
      //get users payroll data
      db.collection("payroll")
        .where("employeeId", "==", this.employeeId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.singlePayroll.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          let data = {
            deductionName: deductionName,
            businessId: this.singlePayroll[0].businessId,
          };
          db.collection("deductions")
            .add(data)
            .then(() => {
              this.snackDeductionLoading = false;
              this.snackSuccessDeductionAdded = true;
            })
            .catch(() => {
              this.snackDeductionLoading = false;
              this.snackErrorDeductionAdded = true;
            });
        });
    },

    filterPayroll() {
      this.monthlyPayRollEarnings =
        this.singlePayroll[0].monthlyPay.earnings.filter(
          (item) => format(item.dateTime.toDate(), "MMMM") == this.selectedMonth
        );

      this.monthlyPayRollDeductions =
        this.singlePayroll[0].monthlyPay.deductions.filter(
          (item) => format(item.dateTime.toDate(), "MMMM") == this.selectedMonth
        );

      let expAmounts = this.monthlyPayRollEarnings.map((item) =>
        Number(item.earningsAmount)
      );
      let expsumAmounts =
        expAmounts.length > 1
          ? expAmounts.reduce((prev, next) => prev + next)
          : expAmounts[0] != null
          ? expAmounts[0]
          : 0;
      this.totalEarnings = expsumAmounts;

      let dedAmounts = this.monthlyPayRollDeductions.map((item) =>
        Number(item.deductionAmount)
      );
      let dedSumAmounts =
        dedAmounts.length > 1
          ? dedAmounts.reduce((prev, next) => prev + next)
          : dedAmounts[0] != null
          ? dedAmounts[0]
          : 0;
      this.totalDeductions = dedSumAmounts;
      this.calculateNetPay();
    },
    viewPayroll() {
      this.$store.commit("SET_SELECTED_BUSINESS_TEMP", "");
      //keep store details in temporary store
      this.$store.commit("SET_SELECTED_BUSINESS_TEMP", this.selectedShop);

      this.$router.push({
        name: "MonthlyPayroll",
      });
    },

    viewIndividualsPayroll(employeeId) {
      this.$router.push({
        name: "ViewPayrollDetails",
        params: { id: employeeId },
      });
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
